.home-page {
    padding: 46px 80px 20px;
    background-color: #EAEAEA;
    height: calc(100vh - 46px);

    h1 {
        font-size: 48px;
        font-weight: 400;
        color: #121111;

        span {
            color: #354959;
            font-weight: 300;
            position: relative;
            display: inline-flex;

            &::after {
                content: "";
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                height: 1px;
                width: 100%;
                background: linear-gradient(90deg, #F27601 0%, #A45001 51.04%, #000 100%);
                display: block;
            }
        }

        strong {
            color: #EC7200;

        }
    }

    h6 {
        font-size: 18px;
        color: #354959;
        text-transform: uppercase;
        font-weight: 400;
        margin-top: 10px;
        letter-spacing: 0.36px;
    }

    .nav-list {
        border-radius: 10px;
        background: rgba(255, 255, 255, 0.30);
        padding: 30px;
        margin: 45px 0 0;

        .card {
            border: 0;
            min-height: 230px;
            display: flex;
            flex-direction: column;
            align-items: center;
            border-radius: 10px;
            background: rgba(18, 17, 17, 0.10);
            box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.05);
            justify-content: center;
            margin: 0 35px;

            .title {
                font-size: 21px;
                color: #9BA7AF;
                font-weight: 400;
                margin-bottom: 15px;
                text-transform: capitalize;
            }
            &.active{
                background: #FFF;
                .title{
                    color: #595A66;
                }
            }
        }

        .col-3 {
            position: relative;

            &::after {
                content: "";
                display: block;
                width: 26px;
                height: 20px;
                position: absolute;
                right: -12px;
                top: 0;
                bottom: 0;
                margin: auto;
                background-image: url("../../../../assets/images/next-arrow.svg");
            }

            &:last-child {
                &::after {
                    display: none;
                }
            }
        }
        
        .row {
            margin: 0 -45px;
        }

    }

    .content {
        max-width: 560px;
        
        span {
            color: #0BA5D6;
            cursor: pointer;
        }
      
    }
    p {
        color: #354959;
        font-size: 14px;
        letter-spacing: 0.28px;
        font-weight: 300;
        margin-bottom: 0;
    }

}
.optimize-page {
    max-width: 770px;
    padding: 10px;
    margin: 10px auto;
    width: 100%;
    height: calc(100vh - 66px);
    .step {
        background-color: #fff;
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        padding: 10px;
        flex: 0 33%;
        flex-direction: column;
        cursor: pointer;
        p {
            font-size: 14px;
            color: #262626cc;
            margin-bottom: 0;
        }
        svg{
            width: 22px;
            height: 22px;
            margin-bottom: 5px;
        }
        &.active-step {
            background-color: #08978E;
            border: 1px dashed #fff;
            p{
                color: #fff;
                font-weight: 600;
            }
            svg *{
                fill: #fff;
                opacity: 1;
            }
        }
    }
    .optimize-step{
        margin-bottom: 10px;
    }
    .form-container {
        background-color: #F9F4F0;
        padding: 15px;
        .middle {
            background-color: #fff;
            padding: 12px;
            border: 1px dashed #C4C4C4;
        }
    }
    .constraints-container {
        background-color: #fff;
        padding: 15px;
        fieldset{
            background-color: #F1EFEF;
            padding: 0 10px;
            width: 100%;
            margin-bottom: 20px;
        }
        .top-tab {
            border-bottom: 1px solid #C4C4C4;
            padding-bottom: 8px;
            margin-bottom: 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .title{
                text-transform: capitalize;
                color: #4C4843;
            }
            button{
                font-size: 12px;
                text-transform: capitalize;
                padding: 0;
                svg{
                    width: 12px;
                    height: auto;
                }
            }
        }
        .file-btn {
            width: 100%;
            border: 1px dashed #918f8f;
            height: 35px;
            background-color: transparent;
            font-size: 12px;
            font-weight: 500;
            color: #08978E;
            svg{
                width: 15px !important;
                height: auto;
                margin-right: 5px;
                path{
                    fill: #08978E;
                }
            }
        }
        table{
            width: 100%;
            margin: 20px 0;
            
            th {
                font-size: 10px;
                color: rgb(56 54 52 / 80%);
                font-weight: 500;
                padding: 8px 0;
            }
            td{
                font-size: 12px;
                color: #383634;
                padding: 6px 0;
                border-bottom:1px solid #dcd8d8;
            }
        }
        ul{
            display: flex;
            padding-left: 0;
            list-style: none;
            flex-wrap: wrap;
            li{
                flex: 0 1 33%;
                font-size: 13px;
                display: flex;
                justify-content: space-between;
                padding-right: 40px;
                margin-bottom: 8px;
                button {
                    width: 17px;
                    height: 17px;
                    padding: 0;
                    border: 0;
                    border-radius: 70%;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    font-weight: 400;
                    margin-left: 10px;
                }
            }
        }
    }
}
.input-custom {
    padding-top: 18px !important;
    label {
        transform: none;
        font-size: 12px;
        color: #383634;
    }
    fieldset{
        border-color: #C4C4C4;
        border-radius: 0;
    }
    input,.MuiSelect-multiple {
        padding: 5px 10px;
        min-height: 36px !important;
    }
}
.small-popup {
    width: 400px;
    table  tr {
       td,th{
        border: 0;
            border-bottom:1px solid #c0c0c0;
            padding: 7px 5px;
       }
        
    }
    tr{
      &:last-child{
        td{
            border-bottom: 0;
        }
      }
    }
}
.dialog-top {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    button{
        min-width: inherit;
        svg *{
            stroke: #333;
        }
    }
}