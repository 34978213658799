.result-page {
  background-color: #fff;
  height: calc(100vh - 86px);
  margin: 20px auto;
  max-width: 1100px;
  width: 100%;
  padding: 20px;
  overflow-y: auto;

  button.compare-btn {
    border: 0;
    background-color: transparent;

    svg {
      width: 12px;
      height: auto;
      margin-right: 5px;
    }
  }

  .search-input {
    position: relative;

    svg {
      position: absolute;
      right: 8px;
      width: 14px;
      height: auto;
      top: 11px;
    }

    input {
      padding-right: 30px !important;
    }
  }
}

.result-output {
  padding: 0 20px;

  .box {
    background: #f5f5f5;
    padding: 15px;
    font-size: 14px;
    color: #262626;
    height: 70px;
    justify-content: center;
  }

  .top-output {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #cbcbcb;
    background: #fefefe;
    padding: 0 15px;
    margin: 0 -20px;
    position: sticky;
    top: 46px;
    z-index: 11;

    .left-output {
      padding: 10px 0;

      ul {
        li {
          display: flex;
          flex-direction: column;
          border-right: 1px solid #cbcbcb;
          height: 40px;
          font-size: 12px;
          justify-content: center;

          &:last-child {
            border-right: 0;
          }

          strong {
            color: #08978e;
          }

          a {
            color: #262626;
            text-decoration: none;

            svg {
              width: 13px;
              height: auto;
              margin-right: 5px;
            }
          }
        }
      }
    }

    .right-output {
      display: flex;
      align-items: center;

      small {
        margin-right: 15px;
        color: #08978e;
        font-size: 12px;
        font-weight: 500;

        svg {
          width: 14px;
          height: 14px;
          margin-right: 8px;
        }
      }

      ul {
        display: flex;
        border-left: 1px solid #cbcbcb;
        margin: 0;
        padding: 10px 6px;

        li {
          display: flex;
          justify-content: center;
          flex-direction: column;
          border: 1px solid #cbcbcb;
          background: #fff;
          padding: 2px 10px;
          margin-left: 10px;
          font-size: 16px;

          span {
            font-size: 11px;
            color: #616161;
            text-transform: uppercase;
          }
        }
      }
    }
  }
}

.noData-found {
  color: #514d4d;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100px;
  font-weight: 500;
}

.table-wrapper .MuiPaper-root {
  box-shadow: none !important;
  border-radius: 0 !important;
}

.custom-table {
  tr {
    th {
      font-size: 13px;
      padding: 10px;
      background-color: #f9f4f0;
      font-weight: 500;
    }

    td {
      font-size: 13px;
      padding: 10px;
      font-weight: 400;
      border-bottom: 1px solid #c4c4c4;

      .table-icon {
        padding: 0;
        min-width: inherit;
        margin-right: 20px;

        svg {
          width: 17px;

          * {
            fill: #383634;
          }
        }

        &:last-child {
          margin-right: 0;
        }
      }

      svg {
        width: 15px;
        height: auto;
      }
    }
  }
}

button.Mui-disabled {
  color: #fff !important;
  opacity: 0.6;
}

.MuiTooltip-tooltip {
  padding: 20px !important;
  max-width: 340px !important;
  border-radius: 8px !important;
  border: 1px solid #535b62 !important;
  box-shadow: -5px 4px 8px 0px rgba(0, 0, 0, 0.3) !important;

  .insight-tooltip {
    width: 300px;

    strong {
      font-size: 16px;
      display: block;
      margin-bottom: 8px;
    }

    li {
      border-radius: 4px;
      background: #e9e5e1;
      padding: 10px;
      margin-bottom: 10px;
      font-size: 12px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &[data-popper-placement*="left"] .MuiTooltip-arrow {
    right: 0;
    margin-right: -10px;
    height: 14px;
    width: 10px;
  }
}
