@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
.MuiDivider-root {
  height: 2px;
  background: #3E3E3E80;
}


body,.MuiTypography-root{
  font-family: 'Roboto', sans-serif !important;
}

html::-webkit-scrollbar,
body::-webkit-scrollbar,
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  background: #696868;
  border-radius: 7px;
}

html::-webkit-scrollbar,
body::-webkit-scrollbar {
  border-radius: 0;
}

::-webkit-scrollbar-thumb {
  background: #696868;
  border-radius: 7px

}

::-webkit-scrollbar-track {
  background: #505050;
}

.App{
  background-color: #EAEAEA;
  overflow: auto;
}
*{
  box-sizing: border-box !important;
}
.title{
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 0.3px;
  color: #262626;
}
.form-control {
  border: 1px solid #C4C4C4 !important;
  background: #FFF !important;
  border-radius: 0 !important;
  font-size: 13px !important;
  min-height: 36px !important;
  height: 36px;
  padding: 5px 12px !important;
}
.form-control:focus{
  box-shadow: none !important;
  border-color: #08978E !important;
}
.form-control .MuiInputBase-input {
  padding: 0;
}