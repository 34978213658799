.landing-dashboard-container {
  height: 100%;
  margin: 60px 60px 0px 60px;
  .welcome-text-container {
    .welcome-text {
      font-size: 48px;
      font-weight: 300;
      line-height: 56.25px;
      letter-spacing: 0.02em;
      text-align: left;
    }
    .welcome-underline {
      border-bottom: 2px solid transparent;
      border-image: linear-gradient(
        90deg,
        #f27601 0%,
        #a45001 51.04%,
        #000000 100%
      );
      border-image-slice: 1;
    }
    .welcome-mix {
      color: #f27601;
    }
    .welcome-subtext {
      font-size: 18px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.02em;
      text-align: left;
    }
    .welcome-description {
      margin-top: 50px;
      width: 40%;
    }
  }
  .tiles-container {
    display: flex;
    margin-top: 50px;
    background: #ffffff4d;
    border-radius: 10px;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 25px;
    .tiles {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 250px;
      width: 250px;
      background: #ffffff;
      border-radius: 10px;
      cursor: pointer;
      position: static;
      &.inactive {
        position: relative;
        background: #1211111a;
        pointer-events: none;
        cursor: auto;
        opacity: 60%;
      }
      .tile-image {
        svg {
          width: 80px;
          height: auto;
        }
      }
      .tile-title {
        font-size: 21px;
        font-weight: 400;
        line-height: 24.61px;
        text-align: center;
      }
      .tile-desc {
        font-size: 14px;
        font-weight: 300;
        line-height: 20px;
        letter-spacing: 0.02em;
        text-align: center;
      }
      .tile-inactive-icon {
        position: absolute;
        top: 10px;
        right: 10px;
      }
    }
  }
}
