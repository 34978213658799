header {
    position: relative !important;
    height: 46px;

    .MuiTypography-h6 {
        font-size: 12px;
        color: #FF9C81;
        text-transform: uppercase;
    }

    .MuiToolbar-dense {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        background-color: #121111;
        min-height: inherit;
        padding: 0 16px;
    }

    .user-profile {
        border-left: 1px solid #000 !important;
        display: flex;

        button {
            border-radius: 0 !important;
            &:hover {
                background-color: transparent;
            }

            .MuiTouchRipple-root {
                display: none;
            }

            svg {
                width: 17px;
                height: auto;
                * {
                    fill: #98AAB8;
                }
                &[data-testid="userIconIcon"] {
                    path {
                        stroke: #98AAB8;
                        fill: transparent;
                    }
                }
            }

            &.active-btn {
                background: rgba(0, 95, 89, 0.50);

                p {
                    color: #fff;

                }
                svg {
                    width: 17px;
                    height: auto;
                    * {
                        fill: #fff;
                    }
                    &[data-testid="userIconIcon"] {
                        path {
                            stroke: #fff;
                        }
                    }
                }
            }
        }

        p.MuiTypography-root {
            font-size: 13px;
            margin-left: 10px;
            color: #98AAB8;
        }
    }

    button.logo-btn {
        padding: 0;
        margin-right: 20px;
        margin-left: 0;
    
        border-radius: 0;
        svg {
            width: 130px;
            height: auto;
        }
    }
    span.dataName {
        display: flex;
        align-items: center;
        height: 46px;
        border-left: 1px solid #514D4A;
        padding-left: 20px;
        font-size: 12px;
        color: #D0E0EC;
        letter-spacing: 0.6px;
        svg{
            margin-right: 10px;
        }
    }
    .border-start {
        border-left-color: #272626 !important;
        border-right-color: #272626 !important;
    }
    .form-control {
        background-color: transparent !important;
        color: #fff;
        width: 230px;
        margin-left: 30px;
        .MuiSelect-icon{
            path{
                fill: #fff;
            }
        }
        &:focus {
            border-color: #ffffff !important;
            color: #fff;
        }
    }
}

.MuiMenu-paper {
    .MuiMenu-list {
        li {
            font-size: 12px;
        }
    }
}

#menu-appbar {
    .MuiMenu-paper {
        min-width: 120px !important;
    }
}