.login-page {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.05);
    max-width: 420px;
    height: 465px;
    margin: auto;
    padding: 40px 60px 20px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    h2 {
        color:  #F0F6FA;;
    }
    .logo{
        margin-bottom: 38px;
        svg{
            width: auto;
            height: auto;
        }
    }
    .form-control{
        min-height: 50px !important;
        width: 300px;
        border-radius: 4px !important;
        &::after,&::before{
            display: none !important;
        }
    }
    button{
        background: #000;
        color: #fff;
        font-size: 16px;
        text-transform: capitalize;
        width: 300px;
        height: 50px;
        justify-content: space-between;
        border-radius: 4px;
        color: #fff !important;
        &.Mui-disabled{
            opacity: 0.7;
        }
    }
    
}
