.loadData-page {
  height: 100%;

  .left-panel {
    background: #fbf8f5;
    padding: 20px;
    color: #262626;
    display: flex;
    flex-direction: column;

    > div {
      max-height: 200px;
      overflow: auto;
      min-height: calc(100% - 40px);
      padding-right: 10px;
      margin-right: -10px;
    }

    .data-step {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;
      border: 1px dashed #585c63;

      .step-header {
        height: 50px;
        display: flex;
        justify-content: space-between;
        padding: 5px 20px;

        h6 {
          sup {
            color: #fc5f5f;
          }
        }

        svg {
          width: 20px;
          height: 20px;
          margin-right: 10px;
        }

        button {
          padding: 0;
          font-size: 12px;

          svg {
            width: 12px;
          }
        }

        h6 {
          font-weight: 400;
          font-size: 16px;
        }
      }

      .success-file {
        position: absolute;
        background-color: #fbf8f5;
        width: 100%;
        text-align: center;
        bottom: 51px;
        top: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        p {
          font-size: 16px;
          &.text-failed {
            color: #970811;
          }
          &.text-success {
            color: #08978e;
          }
        }

        .file-name {
          display: flex;
          margin-left: 10px;
          align-items: center;

          p {
            font-size: 10px;
            margin-bottom: 0;
            margin-right: 5px;
            max-width: 300px;
            // color: #08978e;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            svg {
              width: 13px;
              height: auto;
              margin-right: 4px;
            }
          }

          button {
            padding: 0;
            font-size: 12px;
            min-width: auto;

            svg {
              height: auto;
              width: 12px;
            }
          }
        }
      }

      .step-content {
        border-top: 1px dashed #585c63;
        height: 210px;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #8b8c8c;
        font-size: 12px;
        position: relative;

        .file-btn {
          border: 0;
          background-color: transparent !important;
          margin-bottom: 10px;

          svg {
            width: 30px;
            height: auto;

            path {
              fill: #8b8c8c;
            }
          }
        }

        h6 {
          font-size: 16px;
          font-weight: 400;
        }
      }

      .data-name {
        margin-top: auto;

        p {
          font-size: 16px;
          color: #383634;
        }
      }
    }

    .bottom-btn {
      margin-top: auto;
      background-color: #fff;
      height: 46px;
      width: 100%;
      text-align: center;
      display: flex;
      justify-content: center;
    }

    button {
      text-transform: capitalize;
      color: #08978e;

      &.Mui-disabled {
        color: currentColor !important;
      }
    }

    h5 {
      font-size: 12px;
      text-transform: uppercase;
      display: block;
      margin-bottom: 15px;
      letter-spacing: 1.2px;
    }
  }

  .custom-tab {
    height: 100%;
    background-color: #eaeaea;
    padding: 18px;
    flex-direction: column;
    display: flex;

    .current-tab,
    .historical-tab {
      flex-direction: row;
      font-size: 13px;

      &::before {
        content: "";
        display: inline-block;
        width: 17px;
        height: 12px;
        margin-right: 10px;
        background-image: url("../../../../assets/icons/current-data.svg");
        background-repeat: no-repeat;
      }

      &.Mui-selected {
        &::before {
          background-image: url("../../../../assets/icons/current-data-active.svg");
        }
      }
    }

    .historical-tab {
      &::before {
        background-image: url("../../../../assets/icons/historical-data.svg");
        width: 16px;
        height: 16px;
      }

      &.Mui-selected {
        &::before {
          background-image: url("../../../../assets/icons/historical-data-active.svg");
        }
      }
    }

    .MuiPaper-root {
      background-color: transparent;
      box-shadow: none !important;
    }

    th {
      background-color: #d7d9e5;
      padding: 10px 13px;
      color: #4c4843;
      font-weight: 600;
      font-size: 13px;
    }

    td {
      padding: 10px 13px;
      border-bottom: 1px solid #c4c4c4;
      font-size: 13px;

      &:last-child {
        text-align: right;

        svg {
          path {
            fill: #08978e;
          }
        }
      }
    }

    .MuiTabPanel-root {
      padding: 11px 0;
      min-height: calc(100% - 40px);
      overflow: auto;
    }
  }
}

body {
  .MuiCheckbox-root {
    padding: 0;
    margin-right: 5px;

    svg {
      path {
        fill: #08978e;
      }
    }
  }
}

button.MuiButtonBase-root.MuiButton-containedPrimary,
button.custom-button,
.custom-button {
  box-shadow: none;
  background-color: #333 !important;
  border-radius: 4px;
  color: #fff;
  border: 0;
  padding: 5px 12px;
  text-transform: capitalize;
  letter-spacing: 0.4px;
  font-weight: normal;
  font-size: 13px;
}

button.MuiButtonBase-root.MuiButton-containedPrimary:hover,
button.custom-button:hover,
.custom-button:hover {
  opacity: 0.8;
}
.custom-button.btn-secondary {
  background-color: #08978e !important;
}
.custom-button.btn-secondary svg {
  width: 14px;
  height: auto;
}
.custom-button.btn-secondary svg * {
  fill: #fff;
}
.custom-check {
  label:first-child {
    width: 100%;
  }
}
.MuiMenu-list {
  min-width: 110px;
}
.validation-popup {
  font-size: 18px;
  font-weight: 600;
  color: #4c4843;
  text-align: center;

  p {
    margin: 25px 0;
  }

  svg {
    width: 71px;
    height: 71px;
  }
}

.graph-icons {
  svg * {
    stroke: rgb(144, 144, 144);
  }

  .active-icon {
    svg * {
      stroke: #08978e;
    }
  }
}

.scenarios-dialog {
  .MuiDialog-paper {
    max-width: 1000px;
    width: 100%;

    .col-3 {
      > div {
        padding: 0 20px;
        border-bottom: 1px dashed #838383;
        display: flex;
        align-items: center;
        min-height: 35px;
        &:nth-child(4),
        &:nth-child(5) {
          height: 200px;
        }
      }
      &:nth-child(3n) {
        background: rgba(243, 243, 243, 1);
      }
      &:first-child {
        background: rgba(30, 30, 30, 0.2);
        font-size: 14px;
        font-weight: 500;
      }
    }
    .name {
      height: 35px;
      padding: 7px 20px;
      background-color: #353535;
      color: #c7c3c0;
      font-size: 14px;
      display: flex;
      align-items: center;
    }
  }
}

.experiments-popup {
  .MuiPaper-root.MuiDialog-paper {
    width: 100%;

    .MuiInputBase-root {
      max-width: 200px;
    }

    .MuiTableContainer-root {
      width: 100%;
      overflow-x: auto;
      min-height: 250px;
      max-height: 300px;
    }
  }
}

.report-popup {
  .report-content {
    border: 1px dashed #585c63;
    background: #e9e5e1;
    padding: 20px;
    max-height: 350px;
    overflow: auto;
    min-height: 150px;
  }
}
.MuiDialog-paper {
  min-width: 250px;
}
.page-loader {
  min-height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.custom-date {
  .MuiStack-root {
    padding-top: 0;
    outline: none;
    .MuiInputBase-formControl {
      min-height: 36px;
      border-radius: 0;
    }
  }
}
