.dashboard-page{
    padding: 15px;
    .card-list {
        .MuiCard-root {
            width: 100%;
            align-items: center;
            justify-content: center;
            display: flex;
            height: 80px;
            box-shadow: none;
            border-radius: 4px;
            text-align: center;
             p {
                font-weight: 600;
                margin-bottom: 0;
                font-size: 30px;
                color: #262626;
                line-height: 1;
            }
            small{
                text-transform: uppercase;
                color: #616161;
                font-size: 13px;
                letter-spacing: 0.6px;
                display: block;
                margin-bottom: 7px;

            }
            .MuiCardContent-root{
                padding: 0;
            }
        }
    }
}
.custom-input{
    background-color: #fff;
    width: 280px;
    .MuiInputBase-input{
        padding: 11px 10px 5px 10px;
        min-height: 40px !important;
        text-align: left;
    }
    fieldset{
        border: 0;
    }
}